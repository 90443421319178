import { useEffect, useState } from 'react'
import Image from 'next/image'
import styleConfig from '@/styles/style-config'
import { isMobile, isTablet } from 'react-device-detect'

const deviceSizeMap = [
  'xxs',
  'md',
  'lg',
  'xl',
  'xxl',
  'g_sm',
  'g_md',
  'custom_sm',
  'custom_md',
].reduce(
  (map, sizeKey) => ({
    ...map,
    [sizeKey]: Number(styleConfig.theme.screens[sizeKey].replace('px', '')),
  }),
  {}
)

const defaultSizes = [
  `(min-width: ${deviceSizeMap.xxl}px) ${deviceSizeMap.xxl}px`,
  `(min-width: ${deviceSizeMap.xl}px) ${deviceSizeMap.xl}px`,
  `(min-width: ${deviceSizeMap.lg}px) ${deviceSizeMap.lg}px`,
  `${deviceSizeMap.md}px`,
].join(',')

function handleContenfulImage({ src = '', width, quality }) {
  // Sometimes, height is passed directly in the src. We need to parse it out and build a new URL.
  const x = new URL(src)
  const customHeight = x.searchParams.get('h')
  const imageUrl = src.split('?')[0]

  const queryParams = ['fm=webp']

  if (width) {
    queryParams.push(`w=${width}`)
  }

  if (customHeight) {
    queryParams.push(`h=${customHeight}`)
  }
  if (quality) {
    queryParams.push(`q=${quality}`)
  }

  return queryParams.length ? `${imageUrl}?${queryParams.join('&')}` : src
}

const handleBigCommerceImage = ({ src = '', width }) => {
  // Calculate closest NextJS Supported image size
  let customWidth = [16, 32, 48, 64, 96, 128, 256, 384, 640, 750, 828].reduce((a, b) => {
    return Math.abs(b - width) < Math.abs(a - width) ? b : a
  })

  if (!customWidth) {
    customWidth = width // Set a medium default value
  }

  let customSrc
  let urlPartials = src.split('original')
  if (urlPartials[1]) {
    customSrc = `${urlPartials[0]}${customWidth}w${urlPartials[1]}`
  } else {
    customSrc = src
  }

  if (process.env.NEXT_PUBLIC_IS_VERCEL === 'true') {
    return `${process.env.NEXT_PUBLIC_BASE_URL}/_next/image?url=${customSrc}&w=${customWidth}&q=90&fm=webp`
  }

  return `${process.env.NEXT_PUBLIC_BASE_URL}/_next/image?url=${customSrc}&w=${customWidth}px&q=90&fm=webp`
}

const loader = (loadedProps) => {
  const { src } = loadedProps
  if (src?.includes('ctfassets')) return handleContenfulImage(loadedProps)
  if (src?.includes('bigcommerce')) return handleBigCommerceImage(loadedProps)
  return src
}

const NextImage = ({
  src,
  srcMap,
  alt = '',
  height,
  width,
  className,
  objectFit = 'contain',
  loading = 'lazy',
  quality = 75,
  priority = false,
  unoptimized = false,
  sizes = null,
}) => {
  // const [srcUrl, setSrcUrl] = useState({
  //   src: '',
  //   width: 0,
  // })

  const [customClass, setCustomClass] = useState(className)
  // function handleImageChangeOnResize() {
  //   if (srcMap) {
  //     if (window.innerWidth > deviceSizeMap.xxs && window.innerWidth < deviceSizeMap.custom_sm) {
  //       setSrcUrl({
  //         src: srcMap.mobile,
  //         width: deviceSizeMap.g_sm,
  //       })
  //     } else if (
  //       window.innerWidth > deviceSizeMap.md &&
  //       window.innerWidth < deviceSizeMap.custom_md
  //     ) {
  //       setSrcUrl({
  //         src: srcMap.tablet,
  //         width: deviceSizeMap.g_md,
  //       })
  //     } else {
  //       setSrcUrl({
  //         src: srcMap.desktop,
  //         width: deviceSizeMap.xl,
  //       })
  //     }
  //   }
  // }

  // useEffect(() => {
  //   window.addEventListener('resize', handleImageChangeOnResize)
  //   return () => {
  //     window.removeEventListener('resize', handleImageChangeOnResize)
  //   }
  // }, [])

  useEffect(() => {
    // handleImageChangeOnResize()

    // Check if background style has been set.
    if (!className || !className.includes('object-') || objectFit === 'cover') {
      setCustomClass(`${className} object-cover`)
    }

    if (objectFit === 'contain') {
      setCustomClass(`${className} object-contain`)
    }
  }, [])

  let imageProps = {
    unoptimized: unoptimized,
    loader: loader,
    className: customClass,
    src: srcMap
      ? `${isMobile ? srcMap.mobile : isTablet ? srcMap.tablet : srcMap.desktop || srcMap.desktop}`
      : `${src}`,
    sizes: sizes ?? defaultSizes,
    alt: alt,
    fill: height || width ? false : true,
    quality: quality,
    height: height,
    width: width,
  }

  if (priority) {
    imageProps['priority'] = true
  } else {
    imageProps['loading'] = loading
  }

  return <Image {...imageProps} />
}

export default NextImage
